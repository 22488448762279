import { SolutionsFiltersContext } from '@/context/solutionsFilters'
import { Solution, SolutionShow } from '@/interfaces'
import axios from 'axios'
import { useContext } from 'react'
import useSWR from 'swr'
import { number } from 'yup'

interface CreateNoteBySolution {
    data: {
        solution_id: number
        title: string
        note: string
    }
}

interface CreateSolution {
    data: {
        voucher_id: number
        products: {
            agent: string
            solution_managment_id: number | string
            product_managment_id: number | string
            package_managment_id: number | string
            price: number | string
        }[]
    }
}

interface CreateOneSolution {
    data: {
        voucher_id: number
        agent: string
        solution_managment_id: number | string
        product_managment_id: number | string
        package_managment_id: number | string
        price: number | string
        solution_parent_id?: number
    }
}

interface SolutionsProps {
    pageIndex: number
}

export const useSolutions = ({ pageIndex = 1 }: SolutionsProps) => {
    const { solutionsFilters } = useContext(SolutionsFiltersContext)
    let params = {
        query_type: 'paginate',
        paginate: '10',
    }

    // @ts-ignore
    if (solutionsFilters.search) params.search = solutionsFilters.search
    // @ts-ignore
    if (solutionsFilters.status) params.status = solutionsFilters.status
    if (solutionsFilters.statusImplementation)
        // @ts-ignore
        params.implementation_status = solutionsFilters.statusImplementation
    if (solutionsFilters.start_date)
        // @ts-ignore
        params.start_date = solutionsFilters.start_date
    // @ts-ignore
    if (solutionsFilters.end_date) params.end_date = solutionsFilters.end_date

    const { data, error, mutate } = useSWR(
        `/api/voucher/solutions?page=${pageIndex}`,
        url =>
            axios({
                method: 'GET',
                url,
                params,
            })
                .then(res => res.data)
                .catch(error => {
                    throw new Error(error.response.data.message)
                }),
    )

    return {
        solutions: data,
        isLoading: !data && !error,
        error,
    }
}

export const getSolutions = () => {
    return axios({
        method: 'GET',
        url: '/api/solution-managment',
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getSolution = ({ solution_id }) => {
    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/${solution_id}`,
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export function useGetSolution(id: number) {
    return useSWR<SolutionShow>(
        ['solution', id],
        () => getSolution({ solution_id: id }),
        {
            revalidateOnFocus: false,
            revalidateIfStale: false,
        },
    )
}

export const getProductManagment = ({ solution_managment_id }) => {
    return axios({
        method: 'GET',
        url: '/api/product-managment',
        params: {
            solution_managment_id,
        },
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getPackageManagment = ({ product_managment_id }) => {
    return axios({
        method: 'GET',
        url: '/api/package-managment',
        params: {
            product_managment_id,
        },
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getStatusImplementationSolution = () => {
    return axios({
        method: 'GET',
        url: '/api/voucher/solutions/status/implementation',
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const createSolutionByVoucher = ({ data }: CreateSolution) => {
    if (!data?.voucher_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/setSolutions`,
        data,
    })
        .then()
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const createOneSolutionByVoucher = ({ data }: CreateOneSolution) => {
    if (!data?.voucher_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions`,
        data,
    })
        .then()
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const updateSolution = ({ solution_id, data }) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'PUT',
        url: `/api/voucher/solutions/${solution_id}`,
        data,
    })
        .then()
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const createNoteBySolution = ({ data }: CreateNoteBySolution) => {
    if (!data?.solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: '/api/voucher/solutions/set-note',
        data,
    })
        .then()
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const deleteNoteBySolution = async ({
    solution_note_id,
}: {
    solution_note_id: number
}) => {
    if (!solution_note_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'DELETE',
        url: `/api/voucher/solutions/delete-note/${solution_note_id}`,
    })
        .then(res => {})
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const deleteFileBySolution = async ({
    file_id,
}: {
    file_id: number
}) => {
    if (!file_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/remove-file`,
        data: {
            id: file_id,
        },
    })
        .then(res => {})
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const uploadFileBySolution = async ({ solution_id, documents }) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/upload`,
        data: {
            solution_id,
            documents,
        },
    })
        .then(res => {})
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const viewFileBySolution = async ({ file_id }) => {
    if (!file_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/download/${file_id}`,
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendDocumentBySolution = async ({ solution_id }) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/send-contract/${solution_id}`,
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendPresencialDocumentBySolution = async ({ solution_id }) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/send-contract-in-site/${solution_id}`,
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const getALLSolutions = ({
    solutionsFilters,
    statusImplementation,
    statusAdministrative,
}) => {
    let params = {
        query_type: 'all',
    }

    // @ts-ignore
    if (solutionsFilters.search) params.search = solutionsFilters.search
    // @ts-ignore
    if (solutionsFilters.status) params.status = solutionsFilters.status
    if (solutionsFilters.statusImplementation)
        // @ts-ignore
        params.implementation_status = solutionsFilters.statusImplementation
    if (solutionsFilters.start_date)
        // @ts-ignore
        params.start_date = solutionsFilters.start_date
    // @ts-ignore
    if (solutionsFilters.end_date) params.end_date = solutionsFilters.end_date

    return axios({
        method: 'GET',
        url: '/api/voucher/solutions',
        params,
    })
        .then(res => {
            return res.data.data.map(item => {
                return {
                    ID: item.id,
                    Solución: item.solution_managment?.label || '',
                    Producto: item.product_managment?.label || '',
                    'Estado administrativo':
                        statusAdministrative?.find(
                            element => element.name === item.status,
                        )?.label || '',
                    'Estado de implementación':
                        statusImplementation?.find(
                            element =>
                                element.name === item.implementation_status,
                        )?.label || '',
                }
            })
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const getFirstJustificationTemplate = async ({ solution_id }) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/justifications/1/${solution_id}`,
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const getJiraComments = async ({ jiraId }: { jiraId: string }) => {
    if (!jiraId) throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/${jiraId}/jira-comments`,
    })
        .then(res => res.data?.data?.comments ?? [])
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const createJiraTask = async ({
    solution_id,
}: {
    solution_id: number
}) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/${solution_id}/create-task`,
    })
        .then(res => res.data?.data?.comments ?? [])
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const saveSignByUUID = async ({
    uuid,
    print_receipt_sign,
}: {
    uuid: string
    print_receipt_sign: string
}) => {
    if (!uuid) throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'PUT',
        url: `/api/voucher/solutions/update/${uuid}`,
        data: {
            print_receipt_sign,
        },
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const saveSignContractByUUID = async ({
    uuid,
    contract_sign,
    contract_signed_date,
    status,
}: {
    uuid: string
    contract_sign: string
    contract_signed_date: string
    status: string
}) => {
    if (!uuid) throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'PUT',
        url: `/api/voucher/solutions/update/${uuid}`,
        data: {
            contract_sign,
            contract_signed_date,
            status,
        },
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const resendOnboardingEmail = async ({ id }: { id: number }) => {
    if (!id) throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/send-onboarding/${id}`,
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const generateDocumentJustificationFlesip = async ({
    id,
}: {
    id: number
}) => {
    if (!id) throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'GET',
        url: `/api/voucher/solutions/justifications-flesip/${id}`,
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendSignLinkByWhatsApp = async ({
    solutionId,
}: {
    solutionId: number
}) => {
    if (!solutionId)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/send-url-sign-contract/${solutionId}`,
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendSignUrlReceipPrinterByWhatsApp = async ({
    solutionId,
}: {
    solutionId: number
}) => {
    if (!solutionId)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/send-url-receipt-printer/${solutionId}`,
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const generateContractByUUID = async ({ uuid }: { uuid: string }) => {
    if (!uuid) throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/generate-contract/${uuid}`,
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const updateFlesipDevice = async ({
    email,
    serial,
    license,
}: {
    email: string
    serial: string
    license: string
}) => {
    if (!email || !serial || !license)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/status/update-flesip-device`,
        data: {
            email,
            serial,
            license,
        },
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const createNewSalePointFlesip = async ({
    solution_id,
    licenses,
}: {
    solution_id: number
    licenses: Array<{ email: string; printer_serial: string; license: string }>
}) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/${solution_id}/add-users-flesip`,
        data: {
            licenses,
        },
    })
        .then()
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendPrinterByCourier = async ({
    solution_id,
    data,
}: {
    solution_id: number
    data: {
        method: string
        printer: string
        name: string
        address: string
        town: string
        postal_code: string
        phone: string
        observations: string
        reference?: string
    }
}) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/${solution_id}/send-printer`,
        data,
    })
        .then()
        .catch(error => {
            if (error?.response?.data?.data?.code === '-69') {
                throw new Error('¡El código postal no existe!')
            }
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const sendComputerByCourier = async ({
    solution_id,
    data,
}: {
    solution_id: number
    data: {
        method: 'Courier'
        device_type: 'computer'
        address: string
        name: string
        observations: string | null
        phone: string
        postal_code: string
        town: string
        province: string
        device_id: number
        weight: number
    }
}) => {
    if (!solution_id)
        throw new Error('Lo sentimos, ha ocurrido un error inesperado')

    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/${solution_id}/send-printer`,
        data,
    })
        .then()
        .catch(error => {
            if (error?.response?.data?.data?.code === '-69') {
                throw new Error('¡El código postal no existe!')
            }
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export const importJustificationFile = async ({
    file_key,
    file_name,
    proccess,
    update_column,
}: {
    file_key: string
    file_name: string
    proccess: boolean
    update_column: string
}) => {
    return axios({
        method: 'POST',
        url: `/api/voucher/solutions/justifications/import`,
        data: {
            file_key,
            file_name,
            proccess,
            update_column,
        },
    })
        .then(res => res.data)
        .catch(error => {
            throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')
        })
}

export function usePackageManagersById(id: number) {
    return useSWR(['package-managment', id], () =>
        getPackageManagment({ product_managment_id: id }),
    )
}
