export function getExtensionRequestUser(profile: any) {
    const hasProfile = Boolean(profile)
    const isExtensionRequest = profile?.extensionRequestList

    return {
        hasProfile,
        isExtensionRequest,
        menus: profile ?? {},
        initialLink: profile?.initialLink,
    }
}
