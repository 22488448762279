const Button = {
    baseStyle: {
        borderRadius: 'lg',
        paddingY: '1',
        transition: 'all 300ms ease-in-out',
    },
    sizes: {
        sm: {
            fontSize: 'sm',
        },
        md: {
            fontSize: 'md',
        },
        full: {
            fontSize: 'md',
            w: 'full',
        },
    },
    variants: {
        solid: {
            bg: 'linikit.principal',
            color: 'white',
            border: '1',
            py: '4',
            px: '5',
            borderColor: 'light',
            _hover: {
                bg: 'linikit.principal',
                opacity: '0.8',
            },
            ':hover[disabled]:hover': {
                bg: 'primary.300',
            },
            _disabled: {
                opacity: '0.4',
                cursor: 'not-allowed',
            },
        },

        whatsapp: {
            bg: 'details.450',
            color: 'white',
            border: '1',
            py: '4',
            px: '5',
            borderColor: 'light',

            _hover: {
                bg: 'details.450',
                opacity: '0.8',
            },
            ':hover[disabled]:hover': {
                bg: 'primary.300',
            },
            _disabled: {
                opacity: '0.4',
                cursor: 'not-allowed',
            },
        },

        info: {
            bg: 'details.500',
            color: 'white',
            border: '1',
            py: '4',
            px: '5',
            borderColor: 'details.500',
            borderRadius: 'lg',

            fontSize: 'sm',
            _hover: {
                bg: 'details.500',
                opacity: '0.8',
            },
            ':hover[disabled]:hover': {
                bg: 'details.500',
            },
            _disabled: {
                opacity: '0.4',
                cursor: 'not-allowed',
            },
        },

        infoDark: {
            bg: 'dark',
            color: 'white',
            border: '1',
            py: '4',
            px: '5',
            borderColor: 'dark',
            borderRadius: 'lg',
            fontSize: 'sm',
            _hover: {
                bg: 'dark',
                opacity: '0.8',
            },
            ':hover[disabled]:hover': {
                bg: 'dark',
            },
            _disabled: {
                opacity: '0.4',
                cursor: 'not-allowed',
            },
        },

        rounded: {
            bg: 'white',
            color: 'blue.700',
            padding: '2',
            borderRadius: 'full',
            fontWeight: 'bold',
            transition: 'all .05s ease-in-out',
            _hover: {
                opacity: '0.8',
            },
            ':hover[disabled]:hover': {
                bg: 'primary.300',
            },
        },

        border: {
            bg: 'none',
            color: 'primary.100',
            border: '1px',
            borderColor: 'primary.100',
            py: '4',
            px: '5',
            textTransform: 'uppercase',

            fontSize: 'sm',
            _hover: {
                color: 'white',
                bg: 'primary.100',
                opacity: '0.8',
            },
            ':hover[disabled]:hover': {
                color: 'white',
                bg: 'primary.300',
            },
            _disabled: {
                opacity: '0.4',
                cursor: 'not-allowed',
            },
        },

        outline: {
            bg: 'transparent',
            borderWidth: 2,
            borderColor: 'secondary',
            color: 'secondary',
        },

        ghost: {
            bg: 'transparent',
            color: '#858585',
        },

        defaultProps: {
            size: 'sm',
            variant: 'solid',
        },
    },
}

export default Button
