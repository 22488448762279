import React, { FC, useState } from 'react'

import { useSWRConfig } from 'swr'
import {
    Box,
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Skeleton,
    SkeletonText,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
    VStack,
    useClipboard,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { CheuronEnd, CheuronMid, CheuronStart } from '../utils'
import {
    CommunityLine,
    FallingStar,
    PhoneLine,
    User3Line,
    ParentLine,
    TeamLine,
} from '../icons'

import { Segmento } from '../insignias'
import { Client, Opportunity } from '@/interfaces'
import { statusArray } from '@/hooks/statusOpportunity'
import { sendShortURL, useOpportunity } from '@/hooks/opportunities'
import { GestorDetail } from './GestorDetail'
import { DistributorDetail } from './DistributorDetail'
import {
    CancelDocumentModal,
    QuesionsIsEspecial,
} from '@/components/opportunities'
import { useAuth } from '@/hooks/auth'
import { UpdateFastOpportunityModal } from './modals/UpdateFastOpportunityModal'
import { SuperAdminForm } from './SuperAdminForm'
import { OPPORTUNITY_STATUS, OPPORTUNITY_VERSIONS } from '@/constants'
import { LinkIcon } from '@chakra-ui/icons'
import {
    IoCopyOutline,
    IoSendOutline,
    IoShareSocialOutline,
} from 'react-icons/io5'
import OpportunityTypeForm from './OpportunityTypeForm'
import OpportunityStatusForm from './OpportunityStatusForm'
import { FaUserFriends } from 'react-icons/fa'
import { HAS_LAPTOP_OPTIONS } from '@/constants/client'

interface Props {
    rolUser: string
    opportunity: Opportunity
    client: Client
    handleProccessModal: () => void
    onOpenQualify: () => void
    onOpenBonus: () => void
    onOpenClose: () => void
    onOpenRepresentative: () => void
    onOpenNoRepresentative: () => void
    isLoading: boolean
}

export const OpportunitiesDetail: FC<Props> = ({
    rolUser,
    opportunity,
    client,
    isLoading,
    handleProccessModal,
    onOpenQualify,
    onOpenBonus,
    onOpenClose,
    onOpenRepresentative,
    onOpenNoRepresentative,
}) => {
    const { mutate } = useSWRConfig()

    const { user } = useAuth({ middleware: 'auth' })
    const { setStatusOpportunity } = useOpportunity({
        id: opportunity?.id,
    })
    const [isLoadingQualified, setIsLoadingQualified] = useState(false)

    const [isLoadingSendLink, setIsLoadingSendLink] = useState(false)

    const toast = useToast()
    const {
        isOpen: isOpenCancelModal,
        onOpen: onOpenCancelModal,
        onClose: onCloseCancelModal,
    } = useDisclosure()

    const {
        isOpen: isOpenQuestion,
        onOpen: onOpenQuestion,
        onClose: onCloseQuestion,
    } = useDisclosure()

    const {
        isOpen: isOpenFast,
        onOpen: onOpenFast,
        onClose: onCloseFast,
    } = useDisclosure()

    const { onCopy } = useClipboard(`axz.es/${opportunity?.short_url}`)

    let semgentColor = colors.primary
    let segmentIcon = <TeamLine color="#7DD0FF" />

    if (opportunity?.client?.employees_number === 2) {
        semgentColor = '#003A47'
        segmentIcon = <ParentLine color="#7DD0FF" />
    }
    if (opportunity?.client?.employees_number === 3) {
        semgentColor = '#16758A'
        segmentIcon = <User3Line color="#7DD0FF" />
    }

    const getBgColor = (index: number): string => {
        if (opportunity?.status === 'lost') {
            return colors['danger-high']
        }
        const indexStatus = statusArray.findIndex(
            item => item.status === opportunity.status,
        )

        if (index === 3 && [4, 5].includes(indexStatus)) {
            return colors['warning-high']
        }
        if (index === 5 && indexStatus === 7) {
            return colors['warning-high']
        }
        if (index === 8 && [13, 14].includes(indexStatus)) {
            return colors['warning-high']
        }
        if (index === 13 && indexStatus === 14) {
            return colors['success-high']
        }
        if (index <= indexStatus) {
            return colors.secondary
        }
        return colors['gray-primary-30']
    }

    const getTextColor = (index: number): string => {
        if (opportunity?.status === 'lost') {
            return 'white'
        }

        const indexStatus = statusArray.findIndex(
            item => item.status === opportunity.status,
        )
        if (index <= indexStatus) {
            return 'white'
        }
        return '#474747'
    }

    const getAddText = (index: number): string => {
        if (index === 2) {
            if (
                opportunity?.status === 'proccess' &&
                opportunity?.url_landing
            ) {
                return 'CLIENTE COMPLETANDO DATOS'
            }
        }

        if (index === 3) {
            if (opportunity?.status === 'qualified_error') {
                return 'ERROR DE FIRMA'
            }

            if (opportunity?.status === 'qualified' && client?.civil_society) {
                return 'SOCIEDAD CIVIL'
            }
        }
        if (index === 5 && opportunity?.status === 'repre_vol_error') {
            return 'ERROR DE FIRMA'
        }
        if (index === 8) {
            if (opportunity?.status === 'bonus_managment_pending') {
                return 'PDTE-GESTIÓN DE BONO'
            }
            if (opportunity?.status === 'bonus_managment_pending_antiquity') {
                return 'PDTE-GESTIÓN DE BONO ANTIGÜEDAD'
            }
            if (opportunity?.status === 'bonus_managment_pending_sent') {
                return 'PDTE-ENVIADO'
            }
            if (
                ['bonus_managment_sent', 'approved', 'correct'].includes(
                    opportunity?.status,
                )
            ) {
                return 'ENVIADO-GESTIÓN DE BONO'
            }
        }

        return ''
    }

    const getAddTextColor = (): string => {
        if (opportunity?.status === 'proccess' && opportunity?.url_landing) {
            return colors.secondary
        }
        if (opportunity?.status === 'qualified' && client?.civil_society) {
            return '#FD7C04'
        }
        if (
            [
                'qualified_pending',
                'repre_vol_error',
                'bonus_managment_pending_antiquity',
                'bonus_managment_pending',
                'bonus_managment_pending_sent',
            ].includes(opportunity?.status)
        ) {
            return '#FD7C04'
        }
        if (
            ['bonus_managment_sent', 'approved', 'correct'].includes(
                opportunity?.status,
            )
        ) {
            return colors['success-high']
        }

        return ''
    }

    let showValidateButton = false

    if (
        user?.profile?.data?.fast_opportunity &&
        [
            'bonus_managment_pending',
            'bonus_managment_pending_antiquity',
        ].includes(opportunity?.status)
    )
        showValidateButton = true

    if (
        client?.employees_number &&
        ['qualified', 'repre_vol', 'repre_vol_error'].includes(
            opportunity?.status,
        )
    ) {
        showValidateButton = true
    }

    const handleDataValidate = () => {
        const {
            status,
            client: { via_firma_links: hasViaLink },
        } = opportunity ?? {}
        const userProfileFastOpportunity =
            user?.profile?.data?.fast_opportunity === 1

        const isQualified = status === OPPORTUNITY_STATUS.qualified.value
        const isVoluntaryRepresentation =
            status === OPPORTUNITY_STATUS.repre_vol.value

        if (userProfileFastOpportunity) onOpenFast()
        else if (isVoluntaryRepresentation && !hasViaLink) {
            onOpenCancelModal()
        } else if (isQualified) {
            if (user?.special_opportunity) onOpenQuestion()
            else onOpenRepresentative()
        }
    }

    const handleQualified = async () => {
        try {
            setIsLoadingQualified(true)

            await setStatusOpportunity({
                opportunity_id: opportunity?.id,
                status: 'qualified',
            })

            mutate(`/api/opportunity/${opportunity?.id}`)
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoadingQualified(false)
    }

    const handleSendLink = async () => {
        setIsLoadingSendLink(true)

        try {
            await sendShortURL({
                opportunity_id: opportunity?.id,
            })

            toast({
                title: 'Éxito',
                description: 'La URL ha sido enviada',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoadingSendLink(false)
    }

    const handleCopy = () => {
        onCopy()

        toast({
            title: 'Link Copiado',
            status: 'info',
            duration: 1000,
            isClosable: true,
        })
    }

    const isAsesoria = opportunity?.opportunity_type === 'asesoria'

    return (
        <Box
            bg={isAsesoria ? 'cyan.100' : 'white'}
            borderRadius="md"
            borderWidth={1}
            boxShadow="lg"
            p={2}
            mt={3}
            w="100%"
            position="relative">
            {isAsesoria && (
                <Tag
                    size="lg"
                    colorScheme="blue"
                    position="absolute"
                    top={-4}
                    right={-2}>
                    <TagLeftIcon boxSize="16px" as={FaUserFriends} />
                    <TagLabel>Asesoria</TagLabel>
                </Tag>
            )}

            {opportunity?.short_url && (
                <HStack
                    fontSize={14}
                    fontWeight={500}
                    spacing="3"
                    justifyContent="flex-end"
                    w="full">
                    <HStack>
                        <LinkIcon />
                        <Link
                            href={`https://axz.es/${opportunity?.short_url}`}
                            isExternal>{`axz.es/${opportunity?.short_url}`}</Link>
                    </HStack>

                    <Menu>
                        <MenuButton
                            bg="white"
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            as={Button}
                            variant="outline"
                            size="sm"
                            isLoading={isLoadingSendLink}
                            rightIcon={
                                <Icon as={IoShareSocialOutline} fontSize="md" />
                            }>
                            Compartir
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                icon={<Icon as={IoSendOutline} fontSize="md" />}
                                onClick={handleSendLink}>
                                Enviar link
                            </MenuItem>
                            <MenuItem
                                icon={<Icon as={IoCopyOutline} fontSize="md" />}
                                onClick={handleCopy}>
                                Copiar link
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            )}

            <Grid
                templateColumns="57px 1fr 1fr 150px"
                gap={6}
                pl={10}
                mt={4}
                mb={4}>
                {!isLoading && client?.employees_number ? (
                    <GridItem>
                        <Segmento
                            color={semgentColor}
                            segment={opportunity.client?.employees_number}
                            icon={segmentIcon}
                        />
                    </GridItem>
                ) : (
                    <GridItem
                        alignItems="center"
                        justifyContent="center"
                        bg={colors['gray-soft-30']}
                        display="flex"
                        h={57}
                        rounded={8}
                        w="100%">
                        <FallingStar color={'#979797'} transform="scale(0.7)" />
                    </GridItem>
                )}

                <GridItem w="100%">
                    <Box alignItems="center" display="flex" mb={4}>
                        <Box mr={1}>
                            <User3Line />
                        </Box>

                        {!isLoading ? (
                            <Text fontSize={13} fontWeight={700} ml={2}>
                                {opportunity.contact_name}
                            </Text>
                        ) : (
                            <SkeletonText
                                ml={2}
                                noOfLines={1}
                                spacing="4"
                                w="full"
                            />
                        )}
                    </Box>
                    {!isLoading && (
                        <Box alignItems="center" display="flex">
                            <DistributorDetail
                                opportunity_id={opportunity?.id}
                                distributor={opportunity?.distributor}
                                rolUser={rolUser}
                            />
                        </Box>
                    )}

                    <HStack hidden={!opportunity}>
                        <Text fontSize={12} fontWeight={700}>
                            ¿Solicitó el ordenador?:
                        </Text>
                        <Text fontSize={12} fontWeight={600} mx={2}>
                            {opportunity?.segment !== 3
                                ? 'No válido'
                                : opportunity?.client?.has_laptop
                                ? HAS_LAPTOP_OPTIONS.yes.label
                                : HAS_LAPTOP_OPTIONS.no.label}
                        </Text>
                    </HStack>

                    {opportunity && (
                        <OpportunityTypeForm
                            id={opportunity.id}
                            opportunity_type={opportunity.opportunity_type}
                        />
                    )}
                    {opportunity?.opportunity_type === 'asesoria' && (
                        <OpportunityStatusForm
                            id={opportunity.id}
                            status={opportunity.status}
                        />
                    )}
                </GridItem>

                <GridItem w="100%">
                    <Box alignItems="center" display="flex" mb={4}>
                        <Box mr={1}>
                            <CommunityLine />
                        </Box>
                        {!isLoading ? (
                            <Text fontSize={13} fontWeight={700} ml={2}>
                                {client?.client_type ? (
                                    <>
                                        {client?.client_type === 's'
                                            ? 'Empresa'
                                            : 'Autónomo'}
                                    </>
                                ) : (
                                    'Sin definir'
                                )}
                            </Text>
                        ) : (
                            <SkeletonText
                                ml={2}
                                noOfLines={1}
                                spacing="4"
                                w="full"
                            />
                        )}
                    </Box>

                    {!isLoading && (
                        <Box alignItems="center" display="flex">
                            <GestorDetail
                                opportunity_id={opportunity?.id}
                                comercial={opportunity?.comercial}
                                rolUser={rolUser}
                            />
                        </Box>
                    )}
                </GridItem>

                <GridItem w="100%">
                    <Box alignItems="center" display="flex" mb={4}>
                        <PhoneLine />
                        {!isLoading ? (
                            <Text
                                fontSize={13}
                                fontWeight={700}
                                ml={2}
                                color={colors.secondary}>
                                {opportunity.phone}
                            </Text>
                        ) : (
                            <SkeletonText
                                ml={2}
                                noOfLines={1}
                                spacing="4"
                                w="full"
                            />
                        )}
                    </Box>
                    <VStack gap="2" alignItems="flex-end">
                        {showValidateButton && (
                            <Button
                                bg={colors.primary}
                                h="28px"
                                color="white"
                                fontSize={12}
                                fontWeight={500}
                                onClick={handleDataValidate}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Validar datos
                            </Button>
                        )}

                        <Button
                            size="sm"
                            hidden={
                                ![
                                    OPPORTUNITY_STATUS.repre_vol_error.value,
                                    OPPORTUNITY_STATUS.repre_vol.value,
                                ].includes(opportunity?.status)
                            }
                            isLoading={isLoadingQualified}
                            onClick={handleQualified}>
                            Regresar a cualificada
                        </Button>
                    </VStack>
                </GridItem>

                {opportunity && Boolean(user?.is_super_admin) ? (
                    <SuperAdminForm
                        opportunity_id={opportunity?.id}
                        status={opportunity?.status}
                        version={opportunity?.opportunity_version}
                    />
                ) : (
                    <>
                        <div></div>
                        <VStack alignItems="flex-start" gap={1}>
                            <Text fontSize={12} fontWeight={500}>
                                Versión de la oportunidad
                            </Text>
                            <Text fontSize="13">
                                {
                                    OPPORTUNITY_VERSIONS.filter(
                                        item =>
                                            item.value ===
                                            opportunity?.opportunity_version,
                                    )[0]?.label
                                }
                            </Text>
                        </VStack>
                    </>
                )}
            </Grid>

            <QuesionsIsEspecial
                isOpen={isOpenQuestion}
                onClose={onCloseQuestion}
                onOpenRepresentative={onOpenRepresentative}
                onOpenNoRepresentative={onOpenNoRepresentative}
            />

            <CancelDocumentModal
                client_id={opportunity?.client?.id}
                isOpen={isOpenCancelModal}
                onClose={onCloseCancelModal}
                onOpenValidate={onOpenRepresentative}
            />

            {user?.profile?.data?.fast_opportunity && opportunity && (
                <UpdateFastOpportunityModal
                    opportunity={opportunity}
                    userName={user?.name}
                    isOpen={isOpenFast}
                    onClose={onCloseFast}
                />
            )}

            <Box
                px={10}
                display="flex"
                justifyContent="wrap"
                fontSize={10}
                fontWeight={400}
                mb={2}>
                {!isLoading ? (
                    <>
                        <CheuronStart
                            color="white"
                            bg={colors.primary}
                            text="Pendiente"
                        />
                        <CheuronMid
                            handleClick={() => {}}
                            color={getTextColor(2)}
                            bg={getBgColor(2)}
                            addText={getAddText(2)}
                            addTextColor={getAddTextColor()}
                            text={'En proceso'}
                        />
                        <CheuronMid
                            hidden={isAsesoria}
                            handleClick={() => {
                                if (
                                    ![
                                        OPPORTUNITY_STATUS.closed.value,
                                        OPPORTUNITY_STATUS.lost.value,
                                        OPPORTUNITY_STATUS.re_requested.value,
                                    ].includes(opportunity?.status)
                                ) {
                                    onOpenQualify()
                                }
                            }}
                            color={getTextColor(3)}
                            bg={getBgColor(3)}
                            addText={getAddText(3)}
                            addTextColor={getAddTextColor()}
                            text="Cualificada"
                        />
                        <CheuronMid
                            hidden={isAsesoria}
                            handleClick={handleProccessModal}
                            color={getTextColor(5)}
                            bg={getBgColor(5)}
                            addText={getAddText(5)}
                            addTextColor={getAddTextColor()}
                            text="Representante voluntario"
                        />
                        <CheuronMid
                            hidden={isAsesoria}
                            handleClick={onOpenBonus}
                            color={getTextColor(8)}
                            bg={getBgColor(8)}
                            addText={getAddText(8)}
                            addTextColor={getAddTextColor()}
                            text="Gestión de bono"
                        />
                        <CheuronEnd
                            handleClick={onOpenClose}
                            color={getTextColor(14)}
                            bg={getBgColor(14)}
                            text={
                                opportunity?.status ===
                                OPPORTUNITY_STATUS.lost.value
                                    ? 'Perdida'
                                    : opportunity?.status ===
                                      OPPORTUNITY_STATUS.re_requested.value
                                    ? 'Resolicitada'
                                    : 'Cerrado'
                            }
                        />
                    </>
                ) : (
                    <Skeleton w="full" height="20px" />
                )}
            </Box>
        </Box>
    )
}
