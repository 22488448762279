import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    FormErrorMessage,
    Icon,
    Input,
    Select,
    SkeletonText,
    Text,
    useToast,
} from '@chakra-ui/react'
import { User3Line } from '../icons'
import { Opportunity } from '@/interfaces'
import { colors } from '@/utils/const'
import { useOpportunity } from '@/hooks/opportunities'
import { phoneNumberWithoutInternational } from '@/utils/utils'
import { FaEdit } from 'react-icons/fa'

const Schema = Yup.object().shape({
    contact_name: Yup.string(),
    phone: Yup.string()
        .min(10, 'Mínimo 10 caracteres')
        .max(14, 'Maximo 14 caracteres'),
    email: Yup.string().email('Correo inválido'),
    contact_hours: Yup.string(),
})

interface Props {
    opportunity: Opportunity
    isLoading: boolean
}

export const ContactCard: FC<Props> = ({ opportunity, isLoading }) => {
    const { updateOpportunity } = useOpportunity({
        id: opportunity?.id,
    })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            contact_name: opportunity?.contact_name || '',
            phone: opportunity?.phone || '',
            email: opportunity?.email || '',
            contact_hours: opportunity?.contact_hours || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoadingUpdate(true)

            try {
                await updateOpportunity({
                    opportunity_id: opportunity?.id,
                    data: {
                        contact_name: values.contact_name,
                        phone: values.phone ? `+${values.phone}` : null,
                        email: values.email,
                        contact_hours: values.contact_hours,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Datos actualizados con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsEditing(false)
            setIsLoadingUpdate(false)
        },
    })

    return (
        <Box w="100%" borderWidth={1} borderRadius="md" boxShadow="lg">
            <Box
                my={2}
                mr={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Box mx={4} my={2} display="flex" alignItems="center">
                    <User3Line transform="scale(1.25)" />

                    <Text ml={2} fontSize={16} color="#858585">
                        Datos de contacto
                    </Text>
                </Box>

                {[
                    'pending',
                    'proccess',
                    'qualified',
                    'qualified_error',
                ].includes(opportunity?.status) && (
                    <Button
                        variant="ghost"
                        aria-label="Edit contact"
                        onClick={() => setIsEditing(!isEditing)}>
                        <Icon as={FaEdit} />
                    </Button>
                )}
            </Box>

            <Divider />

            <form onSubmit={formik.handleSubmit}>
                <Box
                    w="100%"
                    px={4}
                    mt={2}
                    mb={6}
                    fontSize={13}
                    fontWeight={400}>
                    <Text color="#4F4F4F" mb={1}>
                        Nombre
                    </Text>
                    {isEditing ? (
                        <FormControl mb={2}>
                            <Input
                                id="contact_name"
                                placeholder="Nombre"
                                rounded="4px"
                                size="xs"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.contact_name}
                            />
                        </FormControl>
                    ) : (
                        <>
                            {!isLoading ? (
                                <Text fontWeight={700} mb={2}>
                                    {opportunity?.client?.name ??
                                        opportunity.contact_name}
                                </Text>
                            ) : (
                                <SkeletonText
                                    noOfLines={1}
                                    spacing="4"
                                    w="70%"
                                />
                            )}
                        </>
                    )}

                    <Text color="#4F4F4F" mb={1}>
                        Teléfono
                    </Text>
                    {isEditing ? (
                        <FormControl
                            mb={2}
                            isInvalid={
                                formik.errors.phone && formik.touched.phone
                            }>
                            <PhoneInput
                                inputStyle={{
                                    fontSize: '12px',
                                    height: '25px',
                                    width: '100%',
                                }}
                                country={'es'}
                                value={formik.values.phone}
                                onChange={phone =>
                                    formik.setFieldValue('phone', phone)
                                }
                            />
                            <FormErrorMessage>
                                {formik.errors.phone}
                            </FormErrorMessage>
                        </FormControl>
                    ) : (
                        <>
                            {!isLoading ? (
                                <Text fontWeight={700} mb={2}>
                                    {opportunity?.client?.phone
                                        ? phoneNumberWithoutInternational(
                                              opportunity.client.phone,
                                          )
                                        : phoneNumberWithoutInternational(
                                              opportunity.phone,
                                          )}
                                </Text>
                            ) : (
                                <SkeletonText
                                    noOfLines={1}
                                    spacing="4"
                                    w="70%"
                                />
                            )}
                        </>
                    )}

                    <Text color="#4F4F4F" mb={1}>
                        Email
                    </Text>
                    {isEditing ? (
                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.email && formik.touched.email
                            }>
                            <Input
                                id="email"
                                bg="white"
                                placeholder="Email"
                                rounded="4px"
                                size="xs"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            <FormErrorMessage>
                                {formik.errors.email}
                            </FormErrorMessage>
                        </FormControl>
                    ) : (
                        <>
                            {!isLoading ? (
                                <Text fontWeight={700} mb={2}>
                                    {opportunity.email}
                                </Text>
                            ) : (
                                <SkeletonText
                                    noOfLines={1}
                                    spacing="4"
                                    w="70%"
                                />
                            )}
                        </>
                    )}

                    <Text color="#4F4F4F" mb={1}>
                        Horas de contacto
                    </Text>

                    {isEditing ? (
                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.contact_hours &&
                                formik.touched.contact_hours
                            }>
                            <Select
                                id="contact_hours"
                                rounded="4px"
                                size="xs"
                                placeholder="Elige un horario"
                                onChange={formik.handleChange}
                                value={formik.values.contact_hours}>
                                <option
                                    value={'Lun-Vie: De 9:00 a 13:00 horas'}>
                                    Lun-Vie: De 9:00 a 13:00 horas
                                </option>
                                <option
                                    value={'Lun-Vie: De 16:00 a 19:00 horas'}>
                                    Lun-Vie: De 16:00 a 19:00 horas
                                </option>
                            </Select>
                            <FormErrorMessage>
                                {formik.errors.contact_hours}
                            </FormErrorMessage>
                        </FormControl>
                    ) : (
                        <>
                            {!isLoading ? (
                                <Text fontWeight={700} mb={2}>
                                    {opportunity?.contact_hours
                                        ? opportunity?.contact_hours
                                        : 'No tiene'}
                                </Text>
                            ) : (
                                <SkeletonText
                                    noOfLines={1}
                                    spacing="4"
                                    w="70%"
                                />
                            )}
                        </>
                    )}

                    {isEditing && (
                        <Center mb={2}>
                            <Button
                                bg={colors.secondary}
                                border="1px"
                                borderColor={colors.secondary}
                                color="white"
                                fontSize={12}
                                fontWeight={500}
                                h="28px"
                                isLoading={isLoadingUpdate}
                                type="submit"
                                w={98}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Guardar
                            </Button>
                        </Center>
                    )}
                </Box>
            </form>

            {/* <Box w="100%" px={8} mt={2} mb={10} fontSize={13} fontWeight={400}>
                <Text color="#4F4F4F">Nombre</Text>
                {!isLoading ? (
                    <Text fontWeight={700}>{opportunity.contact_name}</Text>
                ) : (
                    <SkeletonText noOfLines={1} spacing="4" w="70%" />
                )}
                <Text color="#4F4F4F" mt={2}>
                    Teléfono{' '}
                </Text>
                {!isLoading ? (
                    <Text fontWeight={700}>{opportunity.phone}</Text>
                ) : (
                    <SkeletonText noOfLines={1} spacing="4" w="70%" />
                )}
                <Text color="#4F4F4F" mt={2}>
                    Email
                </Text>
                {!isLoading ? (
                    <Text fontWeight={700}>{opportunity.email}</Text>
                ) : (
                    <SkeletonText noOfLines={1} w="70%" />
                )}
            </Box> */}
        </Box>
    )
}
