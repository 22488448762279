import { User } from '@/interfaces'
import axios from '@/lib/axios'
import useSWR from 'swr'

interface UserProps {
    user_id: number
}

interface NewUserProps {
    userData: NewUser
}

interface NewUser {
    name: string
    alias?: string
    email: string
    roles: number[]
    password?: string
    profile_user_id?: number
}

interface GetUsersProps {
    jira_account_id?: number
    is_bonus_responsible?: number
}

export const useUser = ({ user_id }: UserProps) => {
    const { data, error, mutate } = useSWR<User>(`/api/user/${user_id}`, url =>
        axios
            .get(url)
            .then(res => res.data.data)
            .catch(error => {}),
    )

    const updateUser = async ({ user_id, data }) => {
        return axios({
            method: 'PUT',
            url: `/api/user/${user_id}`,
            data,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                if (
                    error.response.data.message ===
                    'The email has already been taken.'
                ) {
                    throw new Error('El correo ya esta en uso')
                }
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const uploadFile = async ({ user_id, documents }) => {
        return axios({
            method: 'POST',
            url: `/api/user/upload`,
            data: {
                user_id,
                documents,
            },
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    return {
        user: data,
        isLoading: !data && !error,
        error,
        updateUser,
        uploadFile,
    }
}

export const getUsers = async ({
    jira_account_id,
    is_bonus_responsible,
}: GetUsersProps) => {
    let params = {}

    // @ts-ignore
    if (jira_account_id) params.jira_account_id = 1
    // @ts-ignore
    if (is_bonus_responsible) params.is_bonus_responsible = 1
    return axios({
        method: 'GET',
        url: '/api/user',
        params,
    })
        .then(res => res.data.data)
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const useUsers = () => {
    const { data, error, mutate } = useSWR<User[]>(`/api/user`, url =>
        axios
            .get(url)
            .then(res => res.data.data)
            .catch(error => {}),
    )

    const createUser = async ({ userData }: NewUserProps): Promise<User> => {
        return axios({
            method: 'POST',
            url: '/api/user',
            data: { ...userData },
        })
            .then(res => {
                mutate()
                return res.data?.data
            })
            .catch(error => {
                if (error.response.data.message === 'validation.unique') {
                    throw new Error('El correo ya esta en uso')
                }
                throw new Error(error.response.data.message)
            })
    }

    const deleteUser = async ({ user_id }) => {
        return axios({
            method: 'DELETE',
            url: `/api/user/${user_id}`,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                if (error.response.status === 400) {
                    throw new Error(error.response.data.message)
                }

                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const viewFile = async ({ file_id }) => {
        return axios({
            method: 'GET',
            url: `/api/user/download/${file_id}`,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }
    const downloadFile = async ({ file_id }) => {
        return axios({
            method: 'GET',
            url: `/api/user/download-blob/${file_id}`,
            responseType: 'blob',
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')

                if (res.headers['content-disposition']) {
                    const name = res.headers['content-disposition']
                        .split('filename=')[1]
                        .replaceAll('"', '')
                    link.setAttribute('download', name)
                } else {
                    const extension = res.data.type.split('/')[1]
                    link.setAttribute('download', `file.${extension}`)
                }
                link.href = url
                document.body.appendChild(link)
                link.click()
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    const getRoles = async () => {
        return axios({
            method: 'GET',
            url: `/api/role`,
            data,
        })
            .then(res => {
                return res.data.data
            })
            .catch(error => {
                throw new Error(
                    '¡Lo sentimos, ha ocurrido un error inesperado!',
                )
            })
    }

    return {
        users: data,
        isLoading: !data && !error,
        error,
        createUser,
        deleteUser,
        getRoles,
        downloadFile,
        viewFile,
    }
}

export function useGetUserProfiles() {
    return useSWR(`api/profile-user`, async url => {
        const { data } = await axios.get(url)

        return data.data
    })
}

export function useGetUserRoles() {
    return useSWR(`api/role`, async url => {
        const { data } = await axios.get(url)

        return data.data
    })
}
